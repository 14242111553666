$primary-color: #41007DFF;
$secondary-color: #FFDC00FF;
$success-color: #008a00;
$error-color: #ff4d4f;

$primary-button-color: #8C00DCFF;

$default-text-color: #333333;

$primary-dark: #f6f6f6;
$secondary-dark: #767676;
