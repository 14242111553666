.layout {
  height: 100%;
  padding-top: 57px;
  
  .header-wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 200;
  }
  
  .main-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}