@import "src/styles/variables";
@import "src/styles/media";

.training-page-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 100px 50px;

  iframe {
    width: 100%;
    height: 700px;
  }
}

@media (max-width: $lg-max) {
  .training-page-wrapper {
    max-width: 100%;
    padding: 50px 20px;

    iframe {
      width: 100%;
      height: 600px;
    }
  }
}

@media (max-width: $xs-max) {
  .training-page-wrapper {
    max-width: 100%;
    padding: 20px 10px;

    iframe {
      width: 100%;
      height: 400px;
    }
  }
}