@import "src/styles/variables";
@import "src/styles/media";

.vicki-page-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 100px 50px;
  
  .section {
    .section-title {
      margin-bottom: 50px;
    }
    
    .body-text {
      text-align: center;
    }
  }
  
  .product-spec-list {
    li {
      margin-bottom: 20px;
    }
  }
  
  .about-vicki-videos-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    
    .about-vicki-video {
      width: 400px;
      height: 225px;
      
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .vicki-pdf-wrapper {
    width: 900px;
    margin: auto;
  }

  .system-media-description {
    width: 600px;
    margin: auto;
  }
  
  .planogram-photo-wrapper {
    display: flex;
    max-width: 100%;
    
    img {
      width: 100%;
    }
  }
  
  .media-criteria-videos-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    
    .media-criteria-video {
      width: 400px;
      max-height: 250px;
    }
  }
  
  .media-criteria-photos-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
    
    .media-criteria-photo {
      width: 400px;
      max-height: 250px;
      
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: $lg-max) {
  .vicki-page-wrapper {
    max-width: 100%;
    padding: 70px 20px;

    .section {
      .section-title {
        margin-bottom: 40px;
      }
    }

    .about-vicki-videos-wrapper {
      .about-vicki-video {
        width: 320px;
        height: 180px;
      }
    }

    .vicki-pdf-wrapper {
      width: 720px;
    }

    .system-media-description {
      width: 500px;
    }

    .media-criteria-videos-wrapper {
      .media-criteria-video {
        width: 320px;
        max-height: 180px;
      }
    }

    .media-criteria-photos-wrapper {
      .media-criteria-photo {
        width: 320px;
        max-height: 180px;
      }
    }
  }
}

@media (max-width: $sm-max) {
  .vicki-page-wrapper {
    .vicki-pdf-wrapper {
      width: 540px;
    }
  }
}

@media (max-width: $xs-max) {
  .vicki-page-wrapper {
    max-width: 100%;
    padding: 40px 10px;

    .section {
      .section-title {
        margin-bottom: 30px;
      }
    }

    .about-vicki-videos-wrapper {
      .about-vicki-video {
        width: 400px;
        height: 225px;
      }
    }

    .vicki-pdf-wrapper {
      width: 100%;
      max-width: 100%;
    }

    .system-media-description {
      width: 400px;
      max-width: 100%;
    }

    .media-criteria-videos-wrapper {
      .media-criteria-video {
        width: 400px;
        max-height: 225px;
      }
    }

    .media-criteria-photos-wrapper {
      .media-criteria-photo {
        width: 400px;
        max-height: 225px;
      }
    }
  }
}