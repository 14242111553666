.form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  
  label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  input {
    border: solid 1px rgba(0, 0, 0, 0.54);
    height: 56px;
    line-height: 1;
    font-size: 14px;
    width: 100%;
    border-radius: 0;
    padding: 8px 12px;
  }
}