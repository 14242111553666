.ml-auto {
  margin-left: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-50 {
  margin-top: 50px !important;
}