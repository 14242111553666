@import "src/styles/variables";
@import "src/styles/media";

.footer {
  width: 100%;
  min-height: 320px;
  background-color: $primary-color;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .footer-content {
    display: flex;
    column-gap: 30px;
    width: 100%;
    flex-wrap: wrap;
  }
  
  .version-info {
    font-size: 12px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 10px;
  }

  .navigation-links {
    flex: 1 0;

    ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      height: 100%;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        a {
          font-size: 18px;
          color: #ffffff;
          text-transform: uppercase;

        }
      }
    }
  }
  
  .pf-logo-wrapper {
    width: 150px;
    height: 180px;
    
    img {
      width: 100%;
      height: 100%;
    }
  }
  
  .contact-info-wrapper {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    
    .logo {
      height: auto;
      width: 200px;
      margin-bottom: 30px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    
    .contact-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: #ffffff;
      
      .contact-info-row {
        margin-bottom: 10px;
        text-align: end;
      }
    }
  }
}

@media (max-width: $sm-max) {
  .footer {
    .contact-info-wrapper {
      align-items: center;
      
      .contact-info {
        align-items: center;

        .contact-info-row {
          text-align: center;
        }
      }
    }
  }
}
