@import "variables";

.section-title {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  color: $default-text-color;
  text-align: center;
  margin: 0 0 20px;
}

.section-sub-title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: $default-text-color;
  text-align: center;
  margin: 0 0 20px;
}

.body-text {
  font-size: 16px;
  color: $default-text-color;
}

.caption {
  font-size: 12px;
  color: $default-text-color;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}