@import "src/styles/variables";
@import "src/styles/media";

.login-page {
  height: 100%;
  width: 100%;
  
  .login-page-header {
    width: 100%;
    overflow-y: hidden;
    height: 57px;
    background-color: $primary-color;
    display: flex;

    .logo {
      height: 100%;
      max-width: 200px;
      flex-shrink: 0;
      padding-bottom: 2px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.page-title {
  font-size: 32px;
  margin-bottom: 50px;
  font-weight: bold;
}

.error-container {
  color: $error-color;
  margin-bottom: 20px;
  margin-left: 14px;
  display: list-item;
}

.login-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 50px;
  margin: 20vh auto 0;
  box-shadow: 0 32px 40px -2px rgba(10, 22, 70, 0.12), 0 0 1px 0 rgba(10, 22, 70, 0.06);
}

@media (max-width: $lg-max) {
  .login-page {
    .login-form-wrapper {
      margin: 15vh auto 0;
    }
  }
}

@media (max-width: $xs-max) {
  .login-page {
    .login-form-wrapper {
      width: 100%;
      height: 100%;
      margin: 0;
      padding-top: 15vh;
    }
  }
}
