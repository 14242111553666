@import "src/styles/variables";
@import "src/styles/media";

.home-page-wrapper {
  .home-banner {
    width: 100%;
    display: flex;
    
    .right-part {
      width: 50%;
      height: 600px;
      display: flex;
      flex-direction: column;
      background-image: url(https://assets.foleon.com/eu-central-1/de-uploads-7e3kk3/48018/bkg_1920x1080.269a8a749a0b.jpg?ext=webp), linear-gradient(270deg, rgb(140, 0, 220) 0%, rgba(65, 0, 125, 0.8) 100%);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 60px 30px 60px 30px;
      align-items: center;

      .logo {
        height: auto;
        width: 250px;
        margin-bottom: 80px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      
      .home-banner-title {
        font-size: 32px;
        font-weight: 700;
        color: #ECD9FEFF;
        line-height: 1em;
        margin-bottom: 70px;
        text-align: center;
      }
      
      .home-banner-link-button {
        a {
          color: #ffffff;
          font-size: 18px;
          font-weight: 700;
          line-height: 1.5em;
        }
      }
    }
    
    .left-part {
      width: 50%;
      height: 600px;
    }
  }
  
  .whats-new-section {
    padding: 100px 50px;
    max-width: 1280px;
    margin: auto;
    
    .whats-new-cards-wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;
      width: 100%;
    }
  }
}

@media (max-width: $lg-max) {
  .home-page-wrapper {
    .home-banner {
      flex-direction: column;

      .right-part {
        width: 100%;
        height: auto;
        padding: 30px;
        align-items: center;

        .logo {
          width: 350px;
        }

        .home-banner-title {
          text-align: center;
          margin-bottom: 50px;
        }
      }

      .left-part {
        width: 100%;
      }
    }

    .whats-new-section {
      padding: 70px 20px;
      max-width: 100%;
    }
  }
}

@media (max-width: $xs-max) {
  .home-page-wrapper {
    .home-banner {
      flex-direction: column;

      .right-part {
        width: 100%;
        height: auto;
        padding: 30px;
        align-items: center;

        .logo {
          width: 250px;
        }

        .home-banner-title {
          text-align: center;
          margin-bottom: 30px;
        }
      }

      .left-part {
        width: 100%;
        height: 400px;
      }
    }
  }
}