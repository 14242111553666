@import "src/styles/variables";

.pdf-viewer-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  border-radius: 10px;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
  position: relative;

  &:hover {
    .page-controls {
      opacity: 1;
    }
  }

  .page-controls {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 40px;
    left: 50%;
    background: white;
    opacity: 0;
    transform: translate(-50%);
    transition: opacity ease-in-out .2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
    border-radius: 4px;
    z-index: 2;

    .page-control-btn {
      width: 44px;
      height: 44px;
      background: white;
      border: 0;
      font: inherit;
      font-size: 16px;
      border-radius: 4px;

      &:hover:not(:disabled) {
        background: #e6e6e6;
        cursor: pointer;
      }
    }
    
    .page-info {
      padding: 5px 10px;
    }
  }
}