@import "variables";
@import "media";

.divider {
  width: 500px;
  margin: 100px auto;
  max-width: 100%;
  
  &.small {
    width: 100px;
    margin: 50px auto;
  }
}

@media (max-width: $lg-max) {
  .divider {
    margin: 70px auto;

    &.small {
      width: 100px;
      margin: 30px auto;
    }
  }
}

@media (max-width: $xs-max) {
  .divider {
    width: 100%;
    margin: 40px auto;
    max-width: 100%;

    &.small {
      width: 100px;
      margin: 20px auto;
    }
  }
}
