@import "src/styles/variables";

.home-banner-slider {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  user-select: none;
  
  .navigation-button {
    position: absolute;
    top: calc(50% - 16px);
    font-size: 32px;
    color: $primary-button-color;
    cursor: pointer;
    
    &:hover {
      color: $primary-color;
    }
    
    &.button-next {
      right: 25px;
    }

    &.button-previous {
      left: 25px;
    }
  }
  
  .slider-image-item {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .slider-navigation-bar {
    position: absolute;
    bottom: 50px;
    display: flex;
    column-gap: 20px;
    
    .navigation-bar-item {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $primary-dark;
      cursor: pointer;
      
      &.active {
        background-color: $secondary-dark;
        cursor: default;
      }
    }
  }
}