@import "variables";

.button {
  display: block;
  text-align: center;
  border-radius: 2px;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  border: none;
  padding: 12px 32px;
  cursor: pointer;
  
  &:disabled {
    cursor: default;
  }
  
  &.success {
    background-color: $success-color;
    color: #ffffff;

    &:disabled {
      background-color: darken($success-color, 5%);
    }

    &:hover {
      background-color: darken($success-color, 5%);
    }
  }
  
  &.primary {
    background-color: $primary-button-color;
    color: #fff;

    &:disabled {
      background-color: darken($primary-button-color, 5%);
    }

    &:hover {
      background-color: darken($primary-button-color, 5%);
    }
  }
  
  &.secondary {
    background-color: $secondary-color;
    color: $primary-color;

    &:disabled {
      background-color: darken($secondary-color, 5%);
    }

    &:hover {
      background-color: darken($secondary-color, 5%);
    }
  }
}

.ant-btn-primary.ant-btn-solid {
  background-color: $primary-button-color;
  
  &:hover:not(:disabled):not(.ant-btn-disabled) {
    background-color: darken($primary-button-color, 5%);
  }
}