@import "src/styles/variables";
@import "src/styles/media";

.order-page-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 100px 50px;
  
  .list-wrapper {
    width: 800px;
    max-width: 100%;
    margin: auto;

    li {
      margin-bottom: 10px;
      
      a {
        margin-left: 5px;
        color: $primary-button-color;
        
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .vicki-kiosk-pricing-wrapper {
    display: flex;
    width: 800px;
    max-width: 100%;
    margin: 50px auto 0;

    img {
      width: 100%;
    }
  }
}

@media (max-width: $lg-max) {
  .order-page-wrapper {
    max-width: 100%;
    padding: 70px 20px;
  }
}

@media (max-width: $xs-max) {
  .order-page-wrapper {
    max-width: 100%;
    padding: 40px 10px;
  }
}