@import "src/styles/variables";
@import "src/styles/media";

.header {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  height: 57px;
  background-color: $primary-color;
  display: flex;
  padding: 0 10px;

  .logo {
    height: 100%;
    max-width: 200px;
    flex-shrink: 0;
    padding-bottom: 2px;

    a {
      display: block;
      height: 100%;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .navigation-links {
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      height: 100%;

      li {
        display: flex;
        align-items: center;
        height: 100%;
        position: relative;
        cursor: pointer;
        border-right: 1px solid rgba(0, 0, 0, 0.1);

        .border-bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          background-color: transparent;
          height: 3px;
          transition: 0.3s;
          margin-left: 50%;
        }

        &:hover {
          .border-bottom {
            width: 100%;
            margin-left: 0;
            background-color: $secondary-color;
          }
        }

        a {
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
          text-decoration: none;
          text-transform: uppercase;
          height: 100%;
          line-height: 57px;
          padding: 0 25px;

          &.active {
            border-bottom: 3px solid $secondary-color;
          }
        }
      }
    }
  }

  .hamburger-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: none;
    
    img {
      width: 100%;
      height: 100%;
    }
  }
  
  .mobile-menu {
    position: fixed;
    top: 57px;
    right: 0;
    height: calc(100vh - 57px);
    width: 0;
    transition: width 0.3s ease-out;
    background: $primary-color;
    color: #fff;
    opacity: 0.9;
    
    &.opened {
      width: 400px;
    }

    ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 30px;

      li {
        display: flex;

        a {
          font-size: 16px;
          font-weight: 600;
          color: #ffffff;
          text-decoration: none;
          text-transform: uppercase;
          line-height: 57px;
          width: 100%;
          cursor: pointer;
          
          &:hover {
            color: $secondary-color;
          }

          &.active {
            color: $secondary-color;
          }
        }
      }
    }
  }
}

@media (max-width: $sm-max) {
  .header {
    justify-content: space-between;
    align-items: center;

    .navigation-links {
      display: none;
    }

    .hamburger-button {
      display: block;
    }
  }
}

@media (max-width: $xs-max) {
  .header {
    justify-content: space-between;
    align-items: center;

    .mobile-menu {
      &.opened {
        width: 100%;
      }
    }
  }
}
