@import "src/styles/variables";
@import "src/styles/media";

.support-page-wrapper {
  max-width: 1280px;
  margin: auto;
  padding: 100px 50px;
  
  .body-text {
    text-align: center;
  }
}

@media (max-width: $lg-max) {
  .support-page-wrapper {
    max-width: 100%;
    padding: 70px 20px;
  }
}

@media (max-width: $xs-max) {
  .support-page-wrapper {
    max-width: 100%;
    padding: 40px 10px;
  }
}